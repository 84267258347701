import Popover from "@mui/material/Popover";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { capitaliseFirst } from "core/model/utils/strings";
import { AuctionNoPatient, Patient, SearchType } from "core/types";
import RSButton from "ds_legacy/components/RSButton";
import { padding, sizing } from "ds_legacy/materials/metrics";
import { useSenderAccountContext } from "dsl/atoms/Contexts";
import { MenuItem } from "dsl/atoms/MenuItem";
import { useDropdown } from "dsl/hooks";
import {
  PRODUCT_TOURS,
  TourAttributeWrapper,
} from "dsl/molecules/useProductTour";
import { useParallelSearchTour } from "dsl/molecules/useProductTour/tours/parallelSearchTour";
import { getPatientTypeOptions } from "dsl/organisms/PatientCreatePage/PatientCreateForm";
import { PlusIcon } from "lucide-react";
import { useTranslations } from "translations";
import { useCreateNewAuction } from "./helpers";

const AddSearchDropdown = ({
  // Auction with the earliest start_date
  firstAuction,
  patient,
}: {
  firstAuction: AuctionNoPatient;
  patient: Patient;
}) => {
  const { anchor, onClose, onOpen, open } = useDropdown();
  const translations = useTranslations();
  const getOntology = useGetOntology();
  const { activeRoles, careseeker } = useSenderAccountContext();

  const [createNewAuction, queryProgress] = useCreateNewAuction({
    firstAuction,
    patient,
  });

  if (!patient.auctions?.length) {
    console.error(
      `no auctions in patient ${patient?.id} to properly calculate available auction create options - ensure auctions are filled`,
    );
  }

  const patientOptions = getPatientTypeOptions({
    patient,
    careseeker,
    roles: activeRoles ?? [],
    translations,
    getOntology,
  });

  const additionalSearchOptions = patientOptions
    .map((option) => {
      if (option.disabled) return null;
      return {
        value: option.value,
        label: option.name,
      };
    })
    ?.truthy();

  useParallelSearchTour({
    hasParallelSearchOption: !!additionalSearchOptions?.length,
  });

  if (!additionalSearchOptions?.length) return null;
  return (
    <TourAttributeWrapper
      tourKey={PRODUCT_TOURS.parallel_search.key}
      stepKey={PRODUCT_TOURS.parallel_search.steps.new_search_tab.key}
    >
      <RSButton
        color="primary"
        id="add_search"
        LeftIcon={PlusIcon}
        loading={queryProgress}
        onClick={onOpen}
        variant="text"
      >
        {translations.actions.addSearch}
      </RSButton>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchor}
        open={open}
        onClose={onClose}
      >
        {additionalSearchOptions.map(
          (element: { label: string; value: SearchType }) => (
            <MenuItem
              sx={{
                width: sizing(16),
                display: "flex",
                justifyContent: "center",
                padding: padding(1),
              }}
              onClick={() => {
                createNewAuction(element.value);
              }}
              key={element.value}
            >
              {capitaliseFirst(element.label.toLowerCase())}
            </MenuItem>
          ),
        )}
      </Popover>
    </TourAttributeWrapper>
  );
};

export default AddSearchDropdown;
