import { useTheme } from "@mui/material";
import { TransitionalCare } from "core/types";
import { Banner } from "ds/ui";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_16,
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import { LockIcon } from "lucide-react";
import { useTranslations } from "translations";

export function FormExplanationHeader({
  transitionalCare,
}: {
  transitionalCare: Readonly<TransitionalCare> | undefined;
}) {
  const theme = useTheme();
  const translations = useTranslations();
  const isFormSaved = transitionalCare?.created_at;

  return (
    <>
      <Subheading
        as="h3"
        margin={margin(0)}
        style={{ fontSize: FONT_SIZE_20, fontWeight: FONT_WEIGHT_BOLD }}
      >
        {isFormSaved
          ? translations.patientForms.transitionalCareForm.subtitleEditForm
          : translations.patientForms.transitionalCareForm.subtitleNew}
      </Subheading>
      <div className="my-2">
        <Banner
          message={
            <>
              <Body as="p" margin={margin(0)} maxWidth="100%">
                {translations.patientForms.transitionalCareForm.createNewBanner}
              </Body>
              <HorizontalLayout aligned margin={margin(1.5, 0)}>
                <LockIcon
                  size={FONT_SIZE_16}
                  style={{
                    fontSize: FONT_SIZE_16,
                    color: theme.palette.grey[600],
                  }}
                />
                <Body as="p" margin={margin(0, 1)}>
                  {
                    translations.patientForms.transitionalCareForm
                      .encryptionInformation
                  }
                </Body>
              </HorizontalLayout>
              <Body
                as="p"
                margin={margin(0)}
                maxWidth="100%"
                fontWeight={FONT_WEIGHT_BOLD}
              >
                {
                  translations.patientForms.transitionalCareForm
                    .mandatoryFieldInformation
                }
              </Body>
            </>
          }
          color="primary"
        />
      </div>
    </>
  );
}
